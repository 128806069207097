import * as React from "react";

import Layout from '../components/layout';

import { StaticImage } from "gatsby-plugin-image";
import BottomSpace from "../components/bottomSpace";
import ClassNames from "../utils/classNames";
import {
    content,
    dogTray,
    dogTrayInfo,
    info,
    left,
    right,
    sectionTitle
} from './hundarna.module.scss';

import Zoom from '../components/Zoom';

const DogsPage = () => {
    return (
        <Layout pageTitle="Hundarna" largeHero={false}>
            <div className={content}>
                <div style={{ marginLeft: "5%", marginRight: "5%" }}>
                    <h1 className={sectionTitle}>Tikar</h1>

                    <div className={dogTray} style={{ marginTop: "64px" }}>
                        <Zoom>
                            <StaticImage loading="eager" src={'../images/lady_utstallning1.jpg'} alt={'Lady, Dvärgpudel'} />
                        </Zoom>

                        <div className={ClassNames(info, right)}>
                            <h2>Amore Vincit Omnia Fire And Blood</h2>

                            <h3 style={{ color: "#484848" }}>"Lady"</h3>

                            <p className={dogTrayInfo}>Distichiasis<br />Patella, UA<br />PRA-prcd, DNA-testad fri A</p>
                            <br />
                            <p className={dogTrayInfo}>Utställd med Excellent, CK och reserv-cert.</p>
                            <br />
                            <p className={dogTrayInfo}>Lady är en aprikosröd dvärgpudel som är barnbarn till min första tik Juni (Dreams In Red Chip N Chili). Hon är en tuff tjej som tar sig an alla utmaningar och älskar alla människor. En tjej som gärna vill vara i centrum och alltid följa med på nya äventyr. </p>
                        </div>
                    </div>

                    <div className={dogTray} style={{ marginTop: "64px" }}>
                        <Zoom>
                            <StaticImage src={'../images/tesla_pa_stock.jpg'} alt={'Tesla, Hovawart. Fotografi Carolina Hofman'} />
                        </Zoom>


                        <div className={ClassNames(info, left)}>
                            <h2>Terahofs Joking Julie </h2>
                            <h3 style={{ color: "#484848" }}>SE25135/2023</h3>

                            <h3 style={{ color: "#484848" }}>"Tesla"</h3>

                            <p className={dogTrayInfo}>HD B</p>
                            <p className={dogTrayInfo}>ED ua (0 + 0)</p>
                            <br />
                            <p className={dogTrayInfo}>Utställd med Excellent, CK, BIR</p>
                            <br />
                            <p className={dogTrayInfo}>Tesla flyttade hem till oss sommaren 2023 och är en fantastisk familjehund samt gårdshund. Hon är följsam och tar sig an alla nya saker med nyfikenhet. Tesla är framåt och glad och planerna är många inför framtiden med denna hund!</p>
                            <br />
                            <p className={dogTrayInfo}><i>Fotografi Carolina Hofman.</i></p>

                            {/* <p className={dogTrayInfo}>Genomfört BPH med skott.</p> */}
                            {/* 
                            <Zoom wrapperClass={chartImageWrapper}>
                                <StaticImage src={'../images/javachart.jpg'} alt={'Java BPH spindeldiagram'}/>
                            </Zoom> */}
                        </div>
                    </div>

                    <div className={dogTray} style={{ marginTop: "64px" }}>
                        <Zoom>
                            <StaticImage src={'../images/lykke_vid_dorr.jpg'} alt={'Lykke, Shetland Sheepdog'} />
                        </Zoom>

                        <div className={ClassNames(info, right)}>
                            <h2>Irocz Faith Of The Heart</h2>
                            <h3 style={{ color: "#484848" }}>SE15425/2024</h3>

                            <h3 style={{ color: "#484848" }}>"Lykke"</h3>

                            <p className={dogTrayInfo}>HD/ED</p>
                            <br />
                            <p className={dogTrayInfo}>En liten tjej som tillkommit i flocken är Lykke. Planen i framtiden är att komma ut på agilitybanorna samt rallylydnad. Hittills har hon bara visat tycke för all träning och är otroligt följsam hund med mycket tryck under tassarna.</p>
                        </div>
                    </div>


                    {/*
                     <div className={dogTray} style={{marginTop: "64px"}}>
                        <Zoom>
                            <StaticImage src={'../images/ciri.jpg'} alt={'Lady, Dvärgpudel'}/>
                        </Zoom>

                        <div className={ClassNames(info, left)}>
                            <h2>Amore Vincit Omnia Fire And Blood</h2>

                            <h3 style={{color: "#484848"}}>"Lady"</h3>

                            <p className={dogTrayInfo}>Distichiasis<br/>Patella, UA<br/>PRA-prcd, DNA-testad fri A</p>
                            <br/>
                            <p className={dogTrayInfo}>Utställd med Excellent, CK och reserv-cert.</p>
                            <br/>
                           

                        </div>
                    </div> 

                    <h1 className={sectionTitle} style={{marginTop: "64px"}}>Hanar</h1>

                    <div className={dogTray} style={{marginTop: "64px"}}>
                        <Zoom>
                            <StaticImage src={'../images/nicke2.jpg'} alt={'Nicke, Dvärgpudel'}/>
                        </Zoom>

                        <div className={ClassNames(info, right)}>
                            <h2>Gently Born Blockbuster</h2>
                            <h3 style={{color: "#484848"}}>SE47143/2020</h3>
                            <h3 style={{color: "#484848"}}>"Nicke"</h3>

                            <p className={dogTrayInfo}>Patella UA</p>
                            <p className={dogTrayInfo}>PRA-prcd, DNA-testad fri A</p>
                            <p className={dogTrayInfo}>Öga UA</p>
                            <p className={dogTrayInfo}>Utställd med Excellent</p>
                        </div>
                    </div> */}

                </div>
            </div>

            <BottomSpace />
        </Layout>
    )
}

export default DogsPage